import React from "react";
import TabsCity from "../Components/TabsCity";

const Atendimento = () => {
  return (
    <>
      <TabsCity />
    </>
  );
};

export default Atendimento;
